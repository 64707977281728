@charset "utf-8";

@font-face {
  font-family: 'EightBitLimit';
  src: local('EightBitLimit'), url(./utils/fonts/EightBitLimit.ttf) format('truetype');
}
@font-face {
  font-family: 'Halo3';
  src: local('Halo3'), url(./utils/fonts/Halo3.ttf) format('truetype');
}
@font-face {
  font-family: 'NamcoRegular';
  src: local('NamcoRegular'), url(./utils/fonts/NamcoRegular.ttf) format('truetype');
}
@font-face {
  font-family: 'SnesItalic';
  src: local('SnesItalic'), url(./utils/fonts/SnesItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'SuperMario286';
  src: local('SuperMario286'), url(./utils/fonts/SuperMario286.ttf) format('truetype');
}

// Brand colors
$purple: #8A4D76;
$pink: #FA7C91;
$brown: #757763;
$green: #34AD4B;
$red: #D66E63;

// Bulma global variables
$primary: $purple;
$link: $pink;
$success: $green;
$danger: $red;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Bulma component variables
$control-border-width: 1px;
$input-shadow: none;
$navbar-item-active-color: $link;

$body-height: 100%;

@import '../node_modules/bulma/bulma.sass';
@import '../node_modules/bulma-floating-button/dist/css/bulma-floating-button.sass';
